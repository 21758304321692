<template>
  <div class="noselect margins3">
    <div>
      <h3 class="disabled">Свободные смены</h3>
      <h3 class="disabled">Отчёт Зарплата по точкам за период</h3>
      <h3 class="disabled">Отчёт Зарплата по точкам детально</h3>
      <h3 class="disabled">Отчёт Зарплата по точкам суммарно</h3>
      <h3 class="pointer" @click="followLink('/logs')">Журнал действий сотрудников</h3>
      <h3 class="pointer" @click="followLink('/turnsbytypes')">Первый отчёт студии</h3>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {};
  },

  methods: {
    followLink(href) {
      this.$router.push(href);
    },
  },
};
</script>
